.loader{
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 9999;
  }
  .loader-relative{
    position: relative;
    left: 50%;
    top: 50%;
    z-index: 9999;
  }
  .react-datepicker-wrapper {
    display: block !important;
  }
  .cross-dig{
    background-image: linear-gradient(to bottom right,  transparent calc(50% - 1px), red, transparent calc(50% + 1px)); 
 }
 .account_close_label{
  font-size: 30px;
    font-weight: bold;
    position: fixed;
    z-index: 9999;
    left: 50%;
    top: 50%;
    border: 1px solid;
    padding: 5px;
    color:red;
    background-color: white;
 }
 .rdt_TableHeadRow{
  padding: 12px 7px;
  vertical-align: middle;
  font-size: 12px;
  font-weight: 700;
  padding-bottom: 0;
  text-transform: uppercase;
  border: 0;
 }
 .checkboxes label {
  display: inline-block;
  padding-right: 10px;
  white-space: nowrap;
}
.checkboxes input {
  vertical-align: middle;
}
.checkboxes label span {
  vertical-align: middle;
}
.pointer{
  cursor: pointer;
}
.nav-item a{
  color: white;
}
#action_id:hover{
  background-color: #51cbce;
  color: white;
  cursor: pointer;
}
.fixTableHead {
  overflow-y: auto;
}
.fixTableHead thead th {
  position: sticky;
  top: 0;
  background-color: #51cbce !important;
  z-index: 9999;
}
.react-datepicker-popper {
  z-index: 10000 !important;
}
.dropdown-menu{
  z-index: 10000 !important;
}
.emi-checkbox .form-check-input{
  opacity: 1 !important;
  visibility: visible !important;
  margin-left: 0 !important;
}
.emi-checkbox input {
  width: 17px;
  height: 17px;
}
.emi-checkbox label {
  font-weight: 700;
  color: red;
  padding-left: 25px !important;
}